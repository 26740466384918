
  /* Styles for the dropdown to match input fields */
.dropdown {
    width: 50%;
    height: 52px;
    padding: 15px; /* Same padding as other inputs */
    background-color: rgba(255, 255, 255, 0.1); /* Same background color */
    color: white; /* Same text color */
    border: 1px solid var(--gray); /* Same border style */
    border-radius: 10px; /* Same border radius */
    font-size: 16px; /* Same font size */
    box-sizing: border-box; /* Ensures padding is included in width */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Same transition */
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); /* Same text shadow */
}

/* Placeholder color (not applicable for select) */
.dropdown::placeholder {
    color: rgba(255, 255, 255, 0.6); /* Same placeholder color */
}

/* Focus state */
.dropdown:focus {
    background-color: rgba(255, 255, 255, 0.2); /* Same focus background color */
    border-color: var(--orange); /* Same focus border color */
    outline: none; /* Remove default outline */
}

/* Custom styles for option elements */
.dropdown option {
    background-color: var(--darkGrey); /* Background color for options */
    color: white; /* Text color for options */
}

/* Hover effect for options (may not work in all browsers) */
.dropdown:hover option {
    background-color: rgba(255, 255, 255, 0.2); /* Hover background color for options */
}

/* Add a custom arrow icon (optional) */
.dropdown::after {
    content: '▼'; /* Add a down arrow */
    position: absolute;
    right: 20px; /* Adjust positioning */
    top: 14px; /* Adjust positioning */
    pointer-events: none; /* Prevent interaction */
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    background: linear-gradient(135deg, rgba(50, 50, 50, 0.8), rgba(80, 80, 80, 0.8)); /* Darker gradient */
    border-radius: 15px; /* Rounded corners */
    backdrop-filter: blur(10px); /* Blur effect */
    position: relative; /* For positioning the pseudo-element */
    overflow: hidden; /* Prevent overflow from inner elements */
    box-shadow: 0 0 3px var(--orange), 0 0 8px var(--orange); /* Glow effect */
}

.contact-form-container::before {
    content: '';
    position: absolute;
    top: -2px; /* Adjust to position the dashed border */
    left: -2px; /* Adjust to position the dashed border */
    right: -2px; /* Adjust to position the dashed border */
    bottom: -2px; /* Adjust to position the dashed border */
    border: 2px dashed var(--orange); /* Dashed border */
    border-radius: 15px; /* Match the rounded corners */
    z-index: -1; /* Place behind the container */
}





  
  .form-section, .info-section {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    border-radius: 23px;
    padding: 30px;
    margin-bottom: 40px;
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for contrast */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

  
  /* Form styles */
  .form-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-header h1 {
    font-size: 59px;
    /* font-weight: 500; */
    /* color: var(--orange); */
    /* margin-bottom: 10px; */
    /* color: #ff8c00; */
    font-family: 'Koulen', sans-serif;
  }
  
  .form-header p {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .input-row {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
  }
  
  label {
    color: var(--orange);
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-row input,
  .emailsub,
  .textarea {
    width: 100%;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid var(--gray);
    border-radius: 10px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  .textarea{
    margin-top: 13px;
  }
  .input-row input::placeholder,
  .emailsub::placeholder,
  .textarea::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .input-row input:focus,
  .emailsub:focus,
  .textarea:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: var(--orange);
    outline: none;
    box-shadow: 0 0 3px var(--orange), 0 0 8px var(--orange); /* Glow effect */
  }
  
  
  .textarea {
    min-height: 150px;
    resize: vertical;
  }
  
  .submitbtn {
    text-align: center;
    margin-top: 30px;
    
  }
  
  .submitbtn button {
    background-color: var(--orange);
    color: white;
    border: none;
    border-radius: 18px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    width: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submitbtn button:hover {
    background-color: #ff6c00;
    transform: translateY(-2px);
  }
  
  /* Info section styles */
  .info-item {
  padding-top: 23px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .info-item .icon {
    color: var(--orange);
    font-size: 24px;
    margin-right: 15px;
  }
  
  .info-item a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .info-item a:hover {
    color: var(--orange);
  }
  
  .map {
    margin-top: 30px;
  }
  
  .map iframe {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 10px;
  }
  
  /* Error messages */
  .error {
    color: #ff4500;
    font-size: 14px;
    margin-top: 5px;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .contact-form-container {
      flex-direction: row;
      gap: 40px;
    }
  
    .form-section, .info-section {
      width: 50%;
    }
  
    .input-row {
      flex-direction: row;
    }
  
    .input-row input {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 767px) {
    .contact-form-container {
      padding: 20px;
    }
  
    .form-section, .info-section {
      padding: 20px;
    }
  
    .form-header h1 {
      font-size: 36px;
    }
  
    .submitbtn button {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .dropdown{
      width: 100%;
    }
    .form-header h1 {
      font-size: 28px;
    }
  
    .form-header p {
      font-size: 1em;
    }
  
    .input-row input,
    .emailsub,
    .textarea {
      font-size: 14px;
    }
  
    .submitbtn button {
      font-size: 16px;
    }
  
    .info-item {
      font-size: 14px;
    }
  }
  
  /* Accessibility and focus styles */
  input:focus,
  textarea:focus,
  button:focus {
    outline: 2px solid var(--orange);
    outline-offset: 2px;
  }
  
  /* Animation for submit button */
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  .submitbtn button:hover {
    animation: pulse 0.5s ease-in-out;
  }