.Footer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center content horizontally */
    /* background-color: #111; Dark background for the footer */
    padding: 2rem;
}

.Footer-container>hr {
    border: 1px solid #333;
    /* Adjust the divider color */
    width: 100%;
}

.footer {
    display: flex;
    flex-direction: row;
    /* Stack icons and logo vertically */
    align-items: center;
    /* Center everything horizontally */
    padding: 1rem 0;
    gap: 1.5rem;
    /* Adjust the space between the elements */
}

.social-links {
    display: flex;
    flex-direction: row;
    /* Stack Instagram and logo in a column */
    gap: 1rem;
    align-items: center;
    /* Center the icons */
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img {
    width: 13rem;
}

.developer-info {
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
    transition: color 0.3s;
    /* color: white; */
    margin-top: 1rem;
    /* Space between logo and developer info */
}

.developer-info:hover {
    color: var(--appColor);
}

.blur-f-1,
.blur-f-2 {
    position: absolute;
    filter: blur(200px);
    z-index: -1;
    /* Keep blurs behind the content */
}

.blur-f-1 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    background: rgba(255, 0, 0, 0.4);
    /* Add transparency */
}

.blur-f-2 {
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    background: rgba(255, 115, 0, 0.4);
    /* Add transparency */
}


/* Add this media query at the end of the file */

@media screen and (max-width: 480px) {
    .social-links {
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
    }
    /* Create two separate rows using order */
    .social-links a {
        order: 1;
        /* All social media icons come first */
    }
    .logo-f {
        order: 2;
        /* Logo comes second */
        width: 100%;
        /* Take full width */
        text-align: center;
        margin-top: 1.5rem;
    }
    .logo-f>img {
        width: 10rem;
    }
}