.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 18rem;
    height: 16rem;
    /* border: 1px solid red; */
    margin-top: -12px;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu > li:hover {
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .logo{
        width: 8rem;
        height: 8rem;
    }
    .header > :nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}